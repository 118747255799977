import {AppState} from '../../store/AppState'
import {Dispatch} from 'redux'
import {connect} from 'react-redux'
import {MainContainer} from './main-container'

const mapStateToProps = ({common}: AppState) => ({
  // saJoo: saJoo,
  // scrollY: common.scrollY
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  // goToPurchase: () => {
  //   scroll.scrollToTop();
  //   dispatch(push("/price"));
  // },
  // fetchSampleInfo: () => {
  //   dispatch(fetchSampleInfo());
  // }
})

export default connect(mapStateToProps, mapDispatchToProps)(MainContainer)
