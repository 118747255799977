import React from 'react'
import SEO from '../seo'
import {Link} from 'gatsby'
import HeroImage from '../common/HeroImage'
import Card from '../common/Card'
import SplitSection from '../common/SplitSection'
import SetupAnalysis from '../common/SetupAnalysis'
import MotherHood from '../common/MotherHood'
import Schedule from '../common/Schedule'

export const MainContainer: React.FC = () => {
  return (
    <div>
      <SEO
        title={'무료 택일, 무료 운세, 사주 풀이'}
        description={
          '무료 출산 택일, 무료 이사 및 길일 택일, 사주 풀이 서비스를 제공합니다.'
        }
      />
      <section className="pt-20 md:pt-40">
        <div className={'container max-w-screen-xl mx-auto px-8 lg:flex'}>
          <div className="text-center lg:text-left lg:w-1/2">
            <h1 className="text-4xl lg:text-5xl xl:text-6xl font-bold leading-none">
              에잇코즈
            </h1>
            <h2 className="text-2xl lg:text-3xl xl:text-4xl font-semibold text-gray-500">
              사주 분석 전문 웹
            </h2>
            <p className="text-xl lg:text-2xl mt-6 font-light">
              에잇코즈에서는 무료 출산 택일, 무료 길일 택일, 사주 분석 등의
              서비스를 제공합니다. 복잡한 사주풀이, 이제 에잇코즈와 함께 보다
              쉽게 하세요.
            </p>
            <div className="mt-8 md:mt-12">
              <Link to={'/sajoo'}>
                <button className="block mx-auto mb-2 sm:inline-block w-64 sm:w-32 py-2 sm:py-3 px-3 sm:mr-4 bg-red-400 hover:bg-red-500 rounded text-white">
                  사주 풀이
                </button>
              </Link>
              <Link to={'/today'}>
                <button className="block mx-auto mb-2 sm:inline-block w-64 sm:w-32 py-2 sm:py-3 px-3 sm:mr-4 bg-blue-400 hover:bg-blue-500 rounded text-white">
                  오늘의 운세
                </button>
              </Link>
              <Link to={'/pick-day'}>
                <button className="block mx-auto mb-2 sm:inline-block w-64 sm:w-32 py-2 sm:py-3 px-3 sm:mr-4 bg-blue-400 hover:bg-blue-500 rounded text-white">
                  일반 택일
                </button>
              </Link>
              <Link to={'/pick-birthday'}>
                <button className="block mx-auto mb-2 sm:inline-block w-64 sm:w-32 py-2 sm:py-3 px-3 sm:mr-4 bg-blue-400 hover:bg-blue-500 rounded text-white">
                  출산 택일
                </button>
              </Link>
            </div>
            <p className="mt-4 text-gray-600 font-thin">
              오늘의 운세, 일반 택일, 출산 택일 모두 무료입니다.
            </p>
          </div>
          <div className="lg:w-1/2">
            <HeroImage />
          </div>
        </div>
      </section>
      <section className="py-20 lg:pb-40 lg:pt-48">
        <div className="container max-w-screen-xl mx-auto text-center">
          <h2 className="text-3xl lg:text-5xl font-semibold">무료 서비스</h2>
          <div className="flex flex-col sm:flex-row sm:-mx-3 mt-12">
            <div className="flex-1 px-3">
              <Card className="mb-8">
                <p className="font-semibold text-xl">오늘의 운세</p>
                <p className="mt-4">
                  명리학을 이용하여 하루의 운세를 맞추는 것은 쉬운 일은
                  아닙니다. 에잇코즈에서는 최신 명리 분석 엔진을 이용하여 오늘
                  당신의 하루 운세 그래프를 무료로 제공합니다.
                </p>
              </Card>
            </div>
            <div className="flex-1 px-3">
              <Card className="mb-8">
                <p className="font-semibold text-xl">일반 택일</p>
                <p className="mt-4">
                  꼭 손 없는 날에만 이사해야 하는 것은 아닙니다. 사람마다 길일은
                  조금씩 다릅니다. 개업, 이사, 잔치 등에 좋은 길일을 무료로
                  택일해 보세요.
                </p>
              </Card>
            </div>
            <div className="flex-1 px-3">
              <Card className="mb-8">
                <p className="font-semibold text-xl">출산 택일</p>
                <p className="mt-4">
                  새로운 생명이 태어나는 시간을 선택하는 것은 쉬운일이 아닙니다.
                  에잇코즈에서는 전문 명리 분석 엔진을 이용하여 30일간의 좋은
                  시간을 무료로 제공해 드립니다.
                </p>
              </Card>
            </div>
          </div>
        </div>
      </section>
      <SplitSection
        primarySlot={
          <div className="lg:pr-32 xl:pr-48">
            <h3 className="text-3xl font-semibold leading-tight">
              오늘의 운세 분석
            </h3>
            <p className="mt-8 text-xl font-light leading-relaxed">
              오늘 하루 중, 당신의 컨디션이 제일 안 좋은 시간은 언제일까요? 또는
              공부가 가장 잘 될 시간은 언제일까요? 에잇코즈에서 무료로 분석해
              보세요.
            </p>
          </div>
        }
        secondarySlot={<SetupAnalysis />}
      />
      <SplitSection
        reverseOrder
        primarySlot={
          <div className="lg:pl-32 xl:pl-48">
            <h3 className="text-3xl font-semibold leading-tight">
              이사, 창업, 파티 등의 택일
            </h3>
            <p className="mt-8 text-xl font-light leading-relaxed">
              창업이나 이사, 파티 등의 행사는 길일에 하는 것이 좋습니다.
              사람마다 자신에게 맞는 길일이 따로 있습니다. 에잇코즈에서는 무려
              90일 간의 기간동안 어느날이 좋은 날인지 알려 드립니다.
            </p>
          </div>
        }
        secondarySlot={<Schedule />}
      />
      <SplitSection
        primarySlot={
          <div className="lg:pr-32 xl:pr-48">
            <h3 className="text-3xl font-semibold leading-tight">
              좋은 출산 시간 찾기
            </h3>
            <p className="mt-8 text-xl font-light leading-relaxed">
              좋은 출산 시간을 찾는 것은 보통 어려운 일이 아닙니다. 하루에 12번,
              1주일이면 84번 사주가 바뀝니다. 그 많은 사주를 사람 손으로 직접
              분석하는 것은 쉽지 않습니다. 하지만 에잇코즈는 강력하고 정확한
              명리 분석 엔진을 이용하여 순식간에 해냅니다.
            </p>
          </div>
        }
        secondarySlot={<MotherHood />}
      />
    </div>
  )
}
