import React from 'react'

// @ts-ignore
const SplitSection = ({primarySlot, secondarySlot, reverseOrder = false}) => (
  <section className="py-20">
    <div className="container max-w-screen-xl mx-auto px-4 items-center flex flex-col lg:flex-row">
      <div className="lg:w-1/2">{primarySlot}</div>
      <div
        className={`mt-10 lg:mt-0 w-full lg:w-1/2 ${
          reverseOrder && `order-last lg:order-first`
        }`}
      >
        {secondarySlot}
      </div>
    </div>
  </section>
)

export default SplitSection
